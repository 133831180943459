<template>
  <!-- FOOTER AREA -->

  <footer id="footer" class="no-print">

    <div class="container">
      <div class="row text-center border_bottom">
        <div class="col-md-12">
          <img src="/images/logo.png" alt="logo" style="cursor:pointer;aspect-ratio: auto 237/50" @click="$router.push({'name':'Home'})"/>
          <p>To make your parking experience the best it can be, Compare Parking Prices, goes above and beyond. In order to assist our customers <br>in traveling without having to worry about their automobile, we provide them with low-cost deals and packages.</p>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-4">
          <div class="footer_widgth">
            <h2>Let's Connect With Us</h2>
            <ul>
              <li class="d-none"><a target="_blank" href="https://www.google.com/maps/place/Fernhills+Business+Centre/@53.6005602,-2.295477,19z/data=!3m2!4b1!5s0x487ba4c406bf9d81:0x96724012819e52b4!4m6!3m5!1s0x487ba4c3f6311ba7:0x5906b9719417d25!8m2!3d53.6005594!4d-2.2948333!16s%2Fg%2F1tplk8hp?entry=ttu"><i class="fa-solid fa-location-dot"></i><span>Fernhills Business Centre Foerster Chambers Todd Street, Gtr Manchester, Bury, England,BL9 5BJ</span></a></li>
              <li><a href="tel:033 33447877"><i class="fa-solid fa-phone"></i>033 33447877</a></li>
              <li><a href="mailto:info@compareparkingprices.co.uk"><i class="fa-solid fa-envelope"></i><span>info@compareparkingprices.co.uk</span></a></li>
            </ul>
          </div>
          <div class="footer_widgth last">
            <h2>We Accept</h2>
            <ul>
              <li><img style="aspect-ratio: auto 1/1" src="/images/card.png" alt="card"/></li>
            </ul>
          </div>
        </div>
        <div class="col-md-8">
          <div class="row">
            <div class="col-lg-5 col-md-4">
              <div class="footer_widgth">
                <h2>Support</h2>
                <ul>
                  <li>
                    <router-link to="/contact-us">Contact Us</router-link>
                  </li>
                  <li>
                    <router-link to="/privacy-policy">Privacy policy</router-link>
                  </li>
                  <li>
                    <router-link to="/terms-and-condition" style="word-break: break-word;">Terms & Conditions</router-link>
                  </li>
                  <li>
                    <router-link to="/cookie-policy" style="word-break: break-word;">Cookie Policy</router-link>
                  </li>
                  <li>
                    <router-link to="/reviews">Write Review</router-link>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-4 col-md-4">
              <div class="footer_widgth">
                <h2>Company</h2>
                <ul>
                  <li>
                    <router-link to="/about-us">About Us</router-link>
                  </li>
                  <li>
                    <router-link to="/my-booking">My Booking</router-link>
                  </li>
                  <li>
                    <router-link to="/faqs">FAQs</router-link>
                  </li>
                  <li><a :href="blogURL">Blog</a></li>
                </ul>
              </div>
            </div>
            <div class="col-lg-3 col-md-4">
              <div class="footer_widgth">
                <h2>Links</h2>
                <ul>
                  <li><a href="#"><img style="aspect-ratio: auto 1/1" src="/images/facebook.png" alt="facebook"/>Facebook</a></li>
                  <li><a href="#"><img style="aspect-ratio: auto 1/1" src="/images/instagram.png" alt="instagram"/>Instagram</a></li>
                  <li><a href="#"><img style="aspect-ratio: auto 1/1" src="/images/twitter.png" alt="twitter"/>Twitter</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bottom_footer text-center">
      <div class="container">
        <div class="row">
          <p>© {{ current_year }} Compare Parking Prices, All rights reserved</p>
        </div>
      </div>
    </div>
  </footer>
  <!-- FOOTER AREA -->
  <div class="terms_btn" ref="cookie_consent_btn" v-on:click="toggleCookieConsent">
    <a href="#">Cookies Consent <i class="fa-solid fa-circle-chevron-right"></i></a>
  </div>
  <div class="cookie-check" ref="cookieCheck" id="alert">
    <div class="container mt-3 mb-3 text-center">
      <a href="#" class="btn btn_danger" style="height: auto !important;width: auto !important;position: absolute;right: 0;top: 0;" v-on:click="toggleCookieConsent"><i class="fa-solid fa-chevron-left" style="margin-right: 4px;"></i>Hide</a>
      <h1>Cookies & Privacy </h1>
      <p>We use cookies to ensure you get the best experience from your visit. You can read more about these cookies and the processing of your personal data in our  <a href="javascript:void(0)" @click="$router.push({'name':'PrivacyPolicy'})">cookies and privacy policy</a>. We ask you to agree to the use of cookies and the processing of personal data before you continue. We do not transfer or share customer personal information with any third party.</p>
      <button class="btn btn_blue mb-2" @click="acceptCookieConsent()">
       Accept Cookies
      </button>
      <button class="btn btn_danger mb-2" @click="rejectCookieConsent()">
        Reject Cookies
      </button>
    </div>
  </div>

</template>
<style>

.cookie-check {
  position: fixed;
  bottom: 10px;
  left: -100%;
  background: #F9F9F9;
  color: #000;
  box-shadow: 0px 1px 11px 0px #000;
  z-index: 150;
  transition: all 1s ease-in-out; /* Set animation duration and easing */
}

.cookie-open {
  left: 10px;
}



.cookie-check .btn.btn_blue,.cookie-check .btn.btn_danger {
  font-size: 8px !important;
  width: 120px !important;
  height: 30px !important;
  margin:5px;
  line-height: 12px !important;
}

.cookie-check h1{
  color: #0D2763 !important;
  font-size: 1.5rem;
  font-width: 800;
  text-align: left;
}

.cookie-check p{
  font-size:13px;
  font-weight: 600;
  text-align: left;
}
</style>
<script>
import moment from "moment";
export default {
  name: "AppFooter",
  data() {
    return {
      current_year : moment().format('YYYY'),
      cookie:"hidden"
    }
  },
  watch: {
    '$route'(to, from) {
      if (to.path !== from.path) {
        document.querySelector('.navbar-collapse.collapse').classList.remove('show');
        if(to.name == "MyBookingDetail")
        {
          document.querySelector('#footer').classList.add('d-none')
        }else{
          document.querySelector('#footer').classList.remove('d-none')
        }
      }
    }
  },
  mounted() {
    this.showCookieConsent();
    this.initTawkTo()
  },
  methods: {
    initTawkTo() {
          var Tawk_API = Tawk_API || {},
              Tawk_LoadStart = new Date();
          (function () {
            var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
            s1.async=true;
            s1.src='https://embed.tawk.to/6481f54f94cf5d49dc5c8f1d/1h2dqchsv';
            s1.charset='UTF-8';
            s1.setAttribute('crossorigin','*');
            s0.parentNode.insertBefore(s1,s0);
            Tawk_API;Tawk_LoadStart;
          })();
    },
    showCookieConsent() {
      // Check if the cookie consent banner has already been accepted or rejected
      if (!this.getCookie('cookieConsent')) {
        // If not, create the banner element and add it to the page
        this.$refs.cookie_consent_btn.classList.add('cookie-btn-open')
      }
    },
    acceptCookieConsent() {
      // Set the cookie consent banner cookie to indicate that the banner has been accepted
      this.setCookie('cookieConsent', 'accepted', 365);
      // Remove the banner element from the page
      this.$refs.cookieCheck.style.display = "none";
    },
    rejectCookieConsent() {
      // Set the cookie consent banner cookie to indicate that the banner has been rejected
      this.setCookie('cookieConsent', 'rejected', 365);
      // Remove the banner element from the page
      this.$refs.cookieCheck.style.display = "none";
    },
    setCookie(c_name, value, exdays) {
      var exdate = new Date();
      exdate.setDate(exdate.getDate() + exdays);
      var c_value = escape(value) + ((exdays == null) ? "" : "; expires=" + exdate.toUTCString());
      document.cookie = c_name + "=" + c_value;
    },
    getCookie(name) {
      // Split the cookie string by ';' to get a list of all cookies
      var cookies = document.cookie.split(';');
      // Iterate through the list of cookies
      for (var i = 0; i < cookies.length; i++) {
        // Split each cookie by '=' to get the name and value
        var cookie = cookies[i].split('=');
        // If the cookie name matches the parameter, return the value
        if (cookie[0].trim() == name) {
          return cookie[1];
        }
      }
      // If no match is found, return null
      return null;
    },
    toggleCookieConsent(){
      if(this.cookie == "hidden"){
        this.$refs.cookie_consent_btn.classList.remove('cookie-btn-open');
        this.$refs.cookieCheck.classList.add('cookie-open');
        this.cookie = "visible";
      }else{
        this.$refs.cookie_consent_btn.classList.add('cookie-btn-open');
        this.$refs.cookieCheck.classList.remove('cookie-open');
        this.cookie = "hidden";
      }
    }
  }
}
</script>

<style scoped>

</style>